import CheckoutEntityListItem from "@/checkout/components/CheckoutEntityListItem"
import { CheckoutListItemTemplateSkeleton } from "@/checkout/components/CheckoutListItemTemplate"
import CheckoutProductListItem from "@/checkout/components/CheckoutProductListItem"
import CheckoutSummaryPlanSelection, {
  CheckoutSummaryPlanSelectionSkeleton,
} from "@/checkout/components/CheckoutSummaryPlanSelection"
import { CheckoutSummary_StartCheckoutMutation } from "@/checkout/summary/__generated__/CheckoutSummary_StartCheckoutMutation.graphql"
import {
  CheckoutSummary_ValidateCheckoutMutation,
  ValidateCheckoutInput,
} from "@/checkout/summary/__generated__/CheckoutSummary_ValidateCheckoutMutation.graphql"
import {
  CheckoutSummaryQuery,
  CheckoutSummaryQuery$data,
} from "@/checkout/summary/__generated__/CheckoutSummaryQuery.graphql"
import { Cart, CheckoutStep, CheckoutUtils } from "@/checkout/utils/CheckoutUtils"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useLabel } from "@/core/context/LabelsContext"
import { useFormStore } from "@/core/form/store/FormStore"
import Format from "@/core/format/format"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { GlobalID, NodeFromConnection } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoAlert,
  DiscoButton,
  DiscoButtonSkeleton,
  DiscoIcon,
  DiscoIconButton,
  DiscoSection,
  DiscoText,
} from "@disco-ui"
import { Theme, useMediaQuery } from "@material-ui/core"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { TestIDProps } from "@utils/typeUtils"
import { observable, runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import { useEffect, useRef } from "react"
import { useLazyLoadQuery } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

const SM_SCREEN_TOTAL_HEIGHT = 225

type Pricing = NodeFromConnection<
  NonNullable<CheckoutSummaryQuery$data["organization"]>["pricings"]
>

export type Props = TestIDProps & {
  cart: Cart
  onCheckout: (params: { validCheckoutId: GlobalID; nextStep: CheckoutStep }) => void
}

export type ValidateCheckoutState = ValidateCheckoutInput & {
  selectedPlanPricingId: GlobalID | null
  hoveredPlanId?: GlobalID | null
}

function CheckoutSummary({ testid = "CheckoutSummary", cart, onCheckout }: Props) {
  const classes = useStyles()
  const activeOrganization = useActiveOrganization()
  const drawer = useGlobalDrawer("checkout")
  const history = useHistory()
  const initialCart = useRef<Cart>(cart)
  const experienceLabel = useLabel("experience")
  const isSmDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"))

  const { organization } = useLazyLoadQuery<CheckoutSummaryQuery>(
    graphql`
      query CheckoutSummaryQuery($id: ID!, $pricingIds: [ID!]!) {
        organization: node(id: $id) {
          ... on Organization {
            defaultMembershipPlan {
              id
              pricing {
                id
              }
            }
            membershipPlans: products(
              type: "membership_plan"
              hideDrafts: true
              hideNonPublic: true
            ) {
              totalCount
            }
            pricings(pricingIds: $pricingIds) {
              edges {
                node {
                  id
                  amountCents
                  frequency
                  kind
                  membershipPlan {
                    id
                    slug
                  }
                  membershipBenefit {
                    product {
                      id
                      slug
                    }
                  }
                  ...CheckoutEntityListItem_PricingFragment
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeOrganization?.id || "",
      pricingIds: cart.items.map((item) => Relay.toGlobalId("Pricing", item.pricingId)),
    }
  )

  const pricings = Relay.connectionToArray(organization?.pricings)
  const pricingsById = ArrayUtils.mapBy(pricings, "id")
  const selectedPricings = cart.items.map(
    (item) => pricingsById[Relay.toGlobalId("Pricing", item.pricingId)]
  )
  const products = pricings.map((p) => ({
    pricingId: p.id,
    ...(p.membershipPlan || p.membershipBenefit?.product),
  }))
  const productsByPricingId = ArrayUtils.mapBy(products, "pricingId")
  const productsById = ArrayUtils.mapBy(products, "id")

  const validateForm = useFormStore<
    CheckoutSummary_ValidateCheckoutMutation,
    ValidateCheckoutState
  >(
    graphql`
      mutation CheckoutSummary_ValidateCheckoutMutation($input: ValidateCheckoutInput!) {
        response: validateCheckout(input: $input) {
          node {
            id
            versionKey
          }
          errors {
            field
            message
          }
        }
      }
    `,
    { cart, selectedPlanPricingId: null, hoveredPlanId: null },
    { showErrorToast: false }
  )

  const createForm = useFormStore<CheckoutSummary_StartCheckoutMutation>(
    graphql`
      mutation CheckoutSummary_StartCheckoutMutation($input: StartCheckoutInput!) {
        response: startCheckout(input: $input) {
          node {
            stripeCheckoutSessionId
          }
          errors {
            field
            message
          }
        }
      }
    `,
    { validCheckoutId: "", versionKey: "" }
  )

  // Validate immediately when the cart is initially loaded
  useEffect(() => {
    // Do not validate if plan selection is still required
    if (cart.orphanedProductIds?.length) return
    validateCheckout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Only show for communities that are prepared for Stripe acacia
  if (activeOrganization?.checkoutVersion !== "stripe_acacia") return null

  // Get any top level errors
  const topLevelErrors = [...validateForm.errors, ...createForm.errors]
    .filter((error) => error.field === "*")
    .map((error) => error.message)

  return (
    <div data-testid={testid} className={classes.container}>
      <div className={classes.content}>
        <div className={classes.lhsContent}>
          <div className={classes.list}>
            {topLevelErrors?.map((error) => (
              <DiscoAlert key={error} severity={"error"} message={error} />
            ))}

            {Boolean(
              validateForm.state.cart.orphanedProductIds?.length ||
                validateForm.state.cart.forcePlanSelection
            ) && (
              <CheckoutSummaryPlanSelection
                testid={`${testid}.planSelection`}
                validateForm={validateForm}
                validateCheckout={validateCheckout}
              />
            )}

            {validateForm.state.cart.orphanedProductIds?.map((pId) => {
              const productId = Relay.toGlobalId("Product", pId)
              const product = productsById[productId]

              return (
                <CheckoutProductListItem
                  key={productId}
                  testid={`${testid}.orphanedProducts.item.${product?.slug}`}
                  productId={productId}
                  planId={validateForm.state.hoveredPlanId}
                  onRemove={handleRemoveOrphanedProduct}
                />
              )
            })}

            {validateForm.state.cart.items.map(({ pricingId: rawEntityId }) => {
              const pricingId = Relay.toGlobalId("Pricing", rawEntityId)
              const pricing = pricingsById[pricingId]
              const product = productsByPricingId[pricingId]
              const hideEntity = shouldHideEntity(pricing)
              if (hideEntity) return null

              return (
                <CheckoutEntityListItem
                  key={pricing.id}
                  testid={`${testid}.item.${product?.slug}`}
                  pricingKey={pricing}
                  validateForm={validateForm}
                  onRemove={handleRemoveEntity}
                  buttons={[
                    <DiscoIconButton
                      key={`view-pricing-${pricing.id}`}
                      className={classes.button}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        navigateToEntity(pricing.id)
                      }}
                      tooltip={`View ${
                        pricing.membershipPlan
                          ? "Membership Plan"
                          : experienceLabel.singular
                      }`}
                    >
                      <DiscoIcon icon={"external-link"} />
                    </DiscoIconButton>,
                    <DiscoIconButton
                      key={`remove-pricing-${pricing.id}`}
                      className={classes.button}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        handleRemoveEntity(pricing.id)
                      }}
                      tooltip={"Remove"}
                    >
                      <DiscoIcon icon={"close"} />
                    </DiscoIconButton>,
                  ]}
                />
              )
            })}
          </div>
        </div>

        <div className={classes.rhsContent}>
          <DiscoSection padding={3} className={classes.totalContainer}>
            <div>
              {!isSmDown && (
                <>
                  <div className={classes.lineItem}>
                    <DiscoText>{"Subtotal:"}</DiscoText>
                    <DiscoText>{getTotal()}</DiscoText>
                  </div>

                  <div className={classes.lineItem}>
                    <DiscoText>{"Taxes:"}</DiscoText>
                    <DiscoText>{"--"}</DiscoText>
                  </div>
                </>
              )}

              <div className={classes.lineItem}>
                <DiscoText variant={"body-md-600"} marginTop={1}>
                  {"Total:"}
                </DiscoText>
                <DiscoText variant={"body-md-600"}>
                  {isSmDown ? getTotal() : "--"}
                </DiscoText>
              </div>
            </div>

            <DiscoAlert
              severity={"info"}
              message={"Taxes and total will be calculated at final checkout."}
              classes={{ root: classes.alert }}
            />

            <DiscoButton
              testid={`${testid}.checkoutButton`}
              onClick={handleSubmit}
              width={"100%"}
              shouldDisplaySpinner={createForm.isSubmitting || validateForm.isSubmitting}
              disabled={!canCheckout()}
            >
              {"Checkout"}
            </DiscoButton>
          </DiscoSection>
        </div>
      </div>
    </div>
  )

  function navigateToEntity(pricingId: GlobalID) {
    const p = pricingsById[pricingId]
    const product = p.membershipPlan || p.membershipBenefit?.product
    if (!product) return

    window.open(
      generatePath(ROUTE_NAMES.PRODUCT.REGISTRATION.ROOT, {
        productSlug: product.slug,
      }),
      "_blank"
    )
  }

  function shouldHideEntity(pricing: Pricing) {
    const { defaultMembershipPlan, membershipPlans } = organization || {}
    // If it is the default free plan
    const isDefaultPlan = pricing.id === defaultMembershipPlan?.pricing?.id
    // If the plan has been selected from plan selection
    const isSelectedPlan = validateForm.state.selectedPlanPricingId === pricing.id
    // If the plan isn't the only item in the cart (has experiences, etc)
    const isOnlyPlanInCart = validateForm.state.cart.items.length === 1
    // If there is only one plan available in the community
    const isOnlyAvailablePlan = (membershipPlans?.totalCount || 0) <= 1
    return isDefaultPlan && !isOnlyPlanInCart && isOnlyAvailablePlan && isSelectedPlan
  }

  function getTotal() {
    if (validateForm.state.cart.orphanedProductIds?.length) return "--"
    const total = CheckoutUtils.sumTotal(selectedPricings)
    return Format.asCurrency(total, { currency: activeOrganization?.currency })
  }

  function handleRemoveOrphanedProduct(productId: GlobalID) {
    if (!validateForm.state.cart.orphanedProductIds) return
    const rawProductId = Relay.rawId(productId)
    const index = validateForm.state.cart.orphanedProductIds.findIndex(
      (id) => id === rawProductId
    )
    if (index === -1) return

    runInAction(async () => {
      validateForm.state.cart.orphanedProductIds?.splice(index, 1)
      const updatedCart = CheckoutUtils.encodeCart(validateForm.state.cart)
      if (drawer.isOpen) {
        drawer.setParams({ cart: updatedCart })
      } else {
        history.replace(generatePath(ROUTE_NAMES.CHECKOUT.SUMMARY, { cart: updatedCart }))
      }
      await validateCheckout()
    })
  }

  function handleRemoveEntity(pricingId: GlobalID) {
    const rawEntityId = Relay.rawId(pricingId)
    const index = cart.items.findIndex((item) => item.pricingId === rawEntityId)
    if (index === -1) return

    runInAction(async () => {
      // Remove the item from the cart
      validateForm.state.cart.items.splice(index, 1)

      // If the pricing that is being removed was the plan that was selected in order
      // to satisfy the orphaned products, then we need to remove the orphaned products
      // from the cart, put them back in the orphaned products list, and reset the selected
      // plan pricing ID, essentially restoring the initial state of the cart
      if (validateForm.state.selectedPlanPricingId === pricingId) {
        validateForm.state = {
          selectedPlanPricingId: null,
          cart: {
            items: observable.array(initialCart.current.items),
            orphanedProductIds: observable.array(
              initialCart.current.orphanedProductIds || []
            ),
            forcePlanSelection: Boolean(initialCart.current.forcePlanSelection),
          },
        }
      }

      const updatedCart = CheckoutUtils.encodeCart(validateForm.state.cart)
      if (drawer.isOpen) {
        drawer.setParams({ cart: updatedCart })
      } else {
        history.replace(generatePath(ROUTE_NAMES.CHECKOUT.SUMMARY, { cart: updatedCart }))
      }

      await validateCheckout()
    })
  }

  async function validateCheckout() {
    const { didSave, response } = await validateForm.submit({
      cart: validateForm.state.cart,
    })
    if (!didSave || !response) return

    // If there are no errors, set the valid checkout ID
    if (response.errors?.length) return
    if (!response.node?.id) return

    // Set the valid checkout ID and versionKey
    createForm.state.validCheckoutId = response.node.id
    createForm.state.versionKey = response.node.versionKey
  }

  function canCheckout() {
    if (validateForm.isSubmitting) return false
    if (validateForm.errors.length) return false
    if (!validateForm.state.cart.items.length) return false

    if (createForm.isSubmitting) return false
    if (createForm.errors.length) return false
    if (!createForm.state.validCheckoutId) return false

    return true
  }

  async function handleSubmit() {
    // Run the validation again to ensure the cart is still valid
    await validateCheckout()

    // Submit the checkout
    const { didSave, response } = await createForm.submit(createForm.state)
    if (!didSave || response?.errors?.length || !response?.node) return

    // If the checkout requires payment, go to the payment step, otherwise go to processing
    onCheckout({
      validCheckoutId: createForm.state.validCheckoutId,
      nextStep: response.node.stripeCheckoutSessionId ? "payment" : "process",
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    height: "100%",
    maxWidth: theme.measure.page.contentMaxWidth,
  },
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  lhsContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
    height: "100%",
    padding: theme.spacing(2.5),
    overflowY: "auto",

    [theme.breakpoints.down("sm")]: {
      paddingBottom: `${SM_SCREEN_TOTAL_HEIGHT}px`,
    },
  },
  rhsContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: theme.spacing(1),
    alignItems: "center",
    height: "100%",
    borderLeft: `1px solid ${theme.palette.constants.divider}`,
    padding: theme.spacing(2.5),
    flexShrink: 0,
    width: "400px",

    [theme.breakpoints.down("md")]: {
      width: "350px",
    },

    [theme.breakpoints.down("sm")]: {
      borderLeft: "none",
      height: `${SM_SCREEN_TOTAL_HEIGHT}px`,
      width: "100%",
      bottom: 0,
      position: "fixed",
    },
  },
  list: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
    width: "100%",
  },
  totalContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: theme.spacing(1.5),
    width: "100%",
    borderRadius: theme.measure.borderRadius.big,
    border: theme.palette.constants.borderSmall,

    [theme.breakpoints.down("xs")]: {
      gap: theme.spacing(1),
      padding: theme.spacing(2),
      boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
    },
  },
  lineItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: theme.spacing(0.5),
  },
  button: {
    backgroundColor: theme.palette.background.paper,
    width: "28px",
    height: "28px",
    padding: theme.spacing(0.5),
    border: theme.palette.constants.borderSmall,
  },
  alert: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
}))

export function CheckoutSummarySkeleton({ cart }: Props) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.lhsContent}>
          <div className={classes.list}>
            {Boolean(cart.orphanedProductIds?.length) && (
              <CheckoutSummaryPlanSelectionSkeleton />
            )}

            {cart.orphanedProductIds?.map((productId) => (
              <CheckoutListItemTemplateSkeleton key={productId} />
            ))}

            {cart.items.map((item) => (
              <CheckoutListItemTemplateSkeleton key={item.pricingId} />
            ))}
          </div>
        </div>

        <div className={classes.rhsContent}>
          <DiscoSection padding={3} className={classes.totalContainer}>
            <div>
              <div className={classes.lineItem}>
                <DiscoText>{"Subtotal:"}</DiscoText>
                <DiscoText>{"--"}</DiscoText>
              </div>

              <div className={classes.lineItem}>
                <DiscoText>{"Taxes:"}</DiscoText>
                <DiscoText>{"--"}</DiscoText>
              </div>

              <div className={classes.lineItem}>
                <DiscoText variant={"body-md-600"} marginTop={1}>
                  {"Total:"}
                </DiscoText>
                <DiscoText variant={"body-md-600"}>{"--"}</DiscoText>
              </div>
            </div>

            <DiscoAlert
              severity={"info"}
              message={"Taxes and total will be calculated at final checkout."}
            />

            <DiscoButtonSkeleton width={"100%"} />
          </DiscoSection>
        </div>
      </div>
    </div>
  )
}

export default Relay.withSkeleton({
  component: observer(CheckoutSummary),
  skeleton: CheckoutSummarySkeleton,
})
