import useStartDirectMessage from "@/chat/hooks/useStartDirectMessage"
import { SidebarContactAdminsCarouselItemFragment$key } from "@/chat/sidebar/contact-admins/__generated__/SidebarContactAdminsCarouselItemFragment.graphql"
import Relay from "@/relay/relayUtils"
import ProfileAvatar from "@/user/common/avatar/ProfileAvatar"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoText, DiscoTextSkeleton } from "@disco-ui"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"

interface Props extends TestIDProps {
  organizationMembershipKey: SidebarContactAdminsCarouselItemFragment$key
}

function SidebarContactAdminsCarouselItem({
  organizationMembershipKey,
  testid = "SidebarContactAdminsCarouselItem",
}: Props) {
  const classes = useStyles()
  const [startDm, isStartingDm] = useStartDirectMessage()

  const organizationMembership =
    useFragment<SidebarContactAdminsCarouselItemFragment$key>(
      graphql`
        fragment SidebarContactAdminsCarouselItemFragment on OrganizationMembership {
          id
          member {
            id
            fullName
            ...ProfileAvatarFragment
          }
        }
      `,
      organizationMembershipKey
    )

  const user = organizationMembership?.member
  if (!user) return null

  return (
    <div className={classes.card} data-testid={testid}>
      <ProfileAvatar testid={`${testid}.ProfileAvatar`} userKey={user} size={48} />
      <DiscoText
        variant={"body-sm-500"}
        testid={`${testid}.name`}
        marginTop={1.5}
        marginBottom={2}
        truncateText={2}
        title={user.fullName}
        className={"fs-mask"}
        align={"center"}
      >
        {user.fullName}
      </DiscoText>
      <DiscoButton
        testid={`${testid}.message-button`}
        onClick={handleClick}
        leftIcon={"message"}
        variant={"outlined"}
        color={"grey"}
        size={"small"}
        className={classes.messageButton}
        disabled={isStartingDm}
        shouldDisplaySpinner={isStartingDm}
      >
        <DiscoText variant={"body-sm-500"} marginLeft={1}>
          {"Message"}
        </DiscoText>
      </DiscoButton>
    </div>
  )

  function handleClick() {
    if (!organizationMembership) return
    startDm([organizationMembership.id])
  }
}

const useStyles = makeUseStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: theme.palette.constants.borderSmall,
    padding: theme.spacing(1.5),
    borderRadius: theme.measure.borderRadius.big,
    boxShadow: theme.palette.groovyDepths.insideCard,
  },
  messageButton: {
    padding: theme.spacing(1),
  },
}))

export function SidebarContactAdminsCarouselItemSkeleton() {
  const classes = useStyles()

  return (
    <div className={classes.card}>
      <Skeleton variant={"circle"} width={40} height={40} />
      <DiscoTextSkeleton
        variant={"body-sm-500"}
        marginTop={1.5}
        marginBottom={2}
        truncateText={2}
        width={"100%"}
        align={"center"}
      />
      <DiscoButton
        leftIcon={"message"}
        variant={"outlined"}
        color={"grey"}
        size={"small"}
        className={classes.messageButton}
      >
        <DiscoText variant={"body-sm-500"} marginLeft={1}>
          {"Message"}
        </DiscoText>
      </DiscoButton>
    </div>
  )
}

export default Relay.withSkeleton({
  component: SidebarContactAdminsCarouselItem,
  skeleton: SidebarContactAdminsCarouselItemSkeleton,
})
