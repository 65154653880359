import ChatChannelMainThread from "@/chat/channel/page/content/ChatChannelMainThread"
import { ThreadPageContentQuery } from "@/chat/channel/page/content/__generated__/ThreadPageContentQuery.graphql"
import NotFoundPageContent from "@/core/route/component/not-found/NotFoundPageContent"
import PageContent from "@/main/page/content/PageContent"
import HeaderContent from "@/main/page/header/HeaderContent"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { ProfileAvatarStackSkeleton } from "@/user/common/avatar-stack/ProfileAvatarStack"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { ChatChannelMessageInputSkeleton } from "@components/chat/channel/message-input/ChatChannelMessageInput"
import { DiscoTextSkeleton } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { range } from "@utils/array/arrayUtils"
import { graphql, useLazyLoadQuery } from "react-relay"
import { useParams } from "react-router-dom"

function ThreadPageContent() {
  const { threadId } = useParams<{ threadId: GlobalID }>()
  const classes = useStyles()

  const { thread } = useLazyLoadQuery<ThreadPageContentQuery>(
    graphql`
      query ThreadPageContentQuery($id: ID!) {
        thread: node(id: $id) {
          ... on Thread {
            id
            ...ChatChannelMainThreadFragment
          }
        }
      }
    `,
    { id: threadId }
  )
  if (!thread) return <NotFoundPageContent />

  return (
    <PageContent
      classes={{
        main: classes.main,
        container: classes.pageContainer,
        content: classes.pageContent,
      }}
    >
      <ChatChannelMainThread
        // Without key the new thread won't re-render when changing pages
        key={thread.id}
        threadKey={thread}
        fullPage
      />
    </PageContent>
  )
}

const useStyles = makeUseStyles((theme) => ({
  main: {
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  pageContainer: {
    height: "100%",
    overflow: "hidden",
    margin: 0,
  },
  pageContent: {
    margin: 0,
    maxWidth: "unset",
  },
  skeletonContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  skeletonMessages: {
    padding: theme.spacing(2),
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    overflowY: "auto",
    "& > div": {
      display: "flex",
      gap: theme.spacing(2),
      alignItems: "flex-start",
      "& > :last-child": {
        flexGrow: 1,
      },
    },
  },
}))

export function ThreadPageContentSkeleton() {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <PageContent classes={{ main: classes.main, container: classes.pageContainer }}>
      <HeaderContent
        leftIcon={
          <Skeleton
            variant={"rect"}
            width={40}
            height={40}
            style={{ borderRadius: theme.measure.borderRadius.medium }}
          />
        }
        title={<DiscoTextSkeleton width={"200px"} />}
      />
      <div className={classes.skeletonContainer}>
        <div className={classes.skeletonMessages}>
          {range(6).map((i) => (
            <div key={i}>
              <ProfileAvatarStackSkeleton stackSize={1} avatarSize={32} />
              <div>
                <DiscoTextSkeleton width={150} />
                <DiscoTextSkeleton width={"100%"} />
                <DiscoTextSkeleton width={"50%"} />
              </div>
            </div>
          ))}
        </div>
        <ChatChannelMessageInputSkeleton />
      </div>
    </PageContent>
  )
}

export default Relay.withSkeleton({
  component: ThreadPageContent,
  skeleton: ThreadPageContentSkeleton,
})
