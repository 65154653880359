/**
 * @generated SignedSource<<9f12f18175ac8ba6fca6d2307ed59b7b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ValidateCheckoutInput = {
  cart: CartInput;
};
export type CartInput = {
  forcePlanSelection?: boolean | null;
  items: ReadonlyArray<CartItemInput>;
  orphanedProductIds?: ReadonlyArray<string> | null;
};
export type CartItemInput = {
  pricingId: string;
};
export type CheckoutSummary_ValidateCheckoutMutation$variables = {
  input: ValidateCheckoutInput;
};
export type CheckoutSummary_ValidateCheckoutMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly id: string;
      readonly versionKey: string;
    } | null;
  };
};
export type CheckoutSummary_ValidateCheckoutMutation = {
  response: CheckoutSummary_ValidateCheckoutMutation$data;
  variables: CheckoutSummary_ValidateCheckoutMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ValidateCheckoutResponse",
    "kind": "LinkedField",
    "name": "validateCheckout",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidCheckout",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "versionKey",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckoutSummary_ValidateCheckoutMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CheckoutSummary_ValidateCheckoutMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4989ef7d60b0cb11b4663371a459f8fb",
    "id": null,
    "metadata": {},
    "name": "CheckoutSummary_ValidateCheckoutMutation",
    "operationKind": "mutation",
    "text": "mutation CheckoutSummary_ValidateCheckoutMutation(\n  $input: ValidateCheckoutInput!\n) {\n  response: validateCheckout(input: $input) {\n    node {\n      id\n      versionKey\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f00a1b150eeb7144a33dd69a92499a0e";

export default node;
