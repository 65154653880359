/**
 * @generated SignedSource<<c4af3f67e14d9b675ffb0f955b696858>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentsListReplyItem_comment$data = {
  readonly content: {
    readonly richEditorDescriptionContent: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"ContentReactionButton_ContentFragment" | "DiscoEditorMentionsFragment">;
  } | null;
  readonly contentId: string;
  readonly creationDatetime: string;
  readonly id: string;
  readonly productId: string | null;
  readonly user: {
    readonly fullName: string;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarWithDetailsFragment">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"CommentMoreActionsDropdownFragment">;
  readonly " $fragmentType": "CommentsListReplyItem_comment";
};
export type CommentsListReplyItem_comment$key = {
  readonly " $data"?: CommentsListReplyItem_comment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentsListReplyItem_comment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentsListReplyItem_comment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creationDatetime",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentMoreActionsDropdownFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProfileAvatarWithDetailsFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "richEditorDescriptionContent",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DiscoEditorMentionsFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ContentReactionButton_ContentFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Comment",
  "abstractKey": null
};
})();

(node as any).hash = "26aa73115072cb23818a3e4c75d06c5a";

export default node;
