/**
 * @generated SignedSource<<b28a5c2187db43132d4be991f3a8fddf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PricingFrequency = "annually" | "monthly" | "weekly" | "%future added value";
export type PricingKind = "free" | "one_time" | "recurring" | "%future added value";
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
export type CheckoutProductListItemQuery$variables = {
  id: string;
};
export type CheckoutProductListItemQuery$data = {
  readonly product: {
    readonly cover?: string;
    readonly endsAt?: string | null;
    readonly id?: string;
    readonly includedInBenefits?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly membershipPlanId: string;
          readonly pricing: {
            readonly amountCents: number;
            readonly frequency: PricingFrequency | null;
            readonly id: string;
            readonly kind: PricingKind;
          };
        };
      }>;
    } | null;
    readonly name?: string;
    readonly slug?: string;
    readonly startsAt?: string | null;
    readonly type?: ProductType;
  } | null;
};
export type CheckoutProductListItemQuery = {
  response: CheckoutProductListItemQuery$data;
  variables: CheckoutProductListItemQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cover",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "hideDrafts",
      "value": true
    }
  ],
  "concreteType": "MembershipBenefitNodeConnection",
  "kind": "LinkedField",
  "name": "includedInBenefits",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MembershipBenefitNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MembershipBenefit",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "membershipPlanId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Pricing",
              "kind": "LinkedField",
              "name": "pricing",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amountCents",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "frequency",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "includedInBenefits(hideDrafts:true)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckoutProductListItemQuery",
    "selections": [
      {
        "alias": "product",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CheckoutProductListItemQuery",
    "selections": [
      {
        "alias": "product",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5c1822eb7cf148a197f425162e26b319",
    "id": null,
    "metadata": {},
    "name": "CheckoutProductListItemQuery",
    "operationKind": "query",
    "text": "query CheckoutProductListItemQuery(\n  $id: ID!\n) {\n  product: node(id: $id) {\n    __typename\n    ... on Product {\n      id\n      name\n      cover\n      startsAt\n      endsAt\n      type\n      slug\n      includedInBenefits(hideDrafts: true) {\n        edges {\n          node {\n            id\n            membershipPlanId\n            pricing {\n              id\n              amountCents\n              frequency\n              kind\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "20c45fabf94960283c41607e38766038";

export default node;
