import { CheckoutStep, CheckoutStepLabels } from "@/checkout/utils/CheckoutUtils"
import { useHistoryStack } from "@/core/history/historyStack"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { DiscoIcon, DiscoIconButton, DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useHistory } from "react-router-dom"

export type Props = TestIDProps & {
  step: CheckoutStep
  hideBack?: boolean
  hideBorder?: boolean
}

function CheckoutPageHeader({
  testid = "CheckoutPageHeader",
  step,
  hideBack = false,
  hideBorder = false,
}: Props) {
  const classes = useStyles({ hideBorder })
  const history = useHistory()
  const historyStack = useHistoryStack()
  const canGoBack = historyStack.length > 1

  return (
    <div data-testid={testid} className={classes.header}>
      <div className={classes.headerContent}>
        <div className={classes.lhs}>
          {canGoBack && !hideBack && (
            <DiscoIconButton onClick={history.goBack} className={classes.backButton}>
              <DiscoIcon icon={"arrow-stem-left"} />
            </DiscoIconButton>
          )}

          <div>
            <DiscoText variant={"body-xs-600-uppercase"} color={"primary.main"}>
              {"Registration"}
            </DiscoText>

            <DiscoText testid={`${testid}.step`} variant={"body-lg-600"}>
              {CheckoutStepLabels[step]}
            </DiscoText>
          </div>
        </div>
      </div>
    </div>
  )
}

type StyleProps = {
  hideBorder: boolean
}

const useStyles = makeUseStyles((theme) => ({
  header: ({ hideBorder }: StyleProps) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    maxHeight: "88px",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2.5),
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.pageHeader,

    ...styleIf(!hideBorder, {
      borderBottom: `1px solid ${theme.palette.constants.divider}`,
    }),
  }),
  headerContent: {
    maxWidth: theme.measure.page.contentMaxWidth,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  lhs: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  backButton: {
    "& svg": {
      color: theme.palette.groovy.neutral[700],
    },
  },
}))

export default observer(CheckoutPageHeader)
