import { CheckoutSummaryPlanSelectionQuery } from "@/checkout/components/__generated__/CheckoutSummaryPlanSelectionQuery.graphql"
import CheckoutEntityListItem, {
  CheckoutEntityListItemSkeleton,
} from "@/checkout/components/CheckoutEntityListItem"
import { CheckoutSummary_ValidateCheckoutMutation } from "@/checkout/summary/__generated__/CheckoutSummary_ValidateCheckoutMutation.graphql"
import { ValidateCheckoutState } from "@/checkout/summary/CheckoutSummary"
import { CheckoutUtils } from "@/checkout/utils/CheckoutUtils"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import FormStore from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import {
  DiscoAlert,
  DiscoIcon,
  DiscoIconButton,
  DiscoSection,
  DiscoText,
} from "@disco-ui"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { TestIDProps } from "@utils/typeUtils"
import { runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import { useLazyLoadQuery } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  validateForm: FormStore<ValidateCheckoutState, CheckoutSummary_ValidateCheckoutMutation>
  validateCheckout: () => Promise<void>
}

function CheckoutSummaryPlanSelection({
  testid = "CheckoutSummaryPlanSelection",
  validateForm,
  validateCheckout,
}: Props) {
  const activeOrganization = useActiveOrganization()
  const classes = useStyles()
  const drawer = useGlobalDrawer("checkout")
  const history = useHistory()

  const { organization } = useLazyLoadQuery<CheckoutSummaryPlanSelectionQuery>(
    graphql`
      query CheckoutSummaryPlanSelectionQuery(
        $id: ID!
        $productIds: [ID!]!
        $ignoreActivePlan: Boolean!
      ) {
        organization: node(id: $id) {
          ... on Organization {
            plansInclusiveOfProducts(
              productIds: $productIds
              ignoreActivePlan: $ignoreActivePlan
            ) {
              edges {
                node {
                  id
                  name
                  slug
                  pricing {
                    id
                    ...CheckoutEntityListItem_PricingFragment
                  }
                  membershipBenefits(productIds: $productIds, hideDrafts: true) {
                    edges {
                      node {
                        id
                        productId
                        pricing {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeOrganization?.id || "",
      productIds:
        validateForm.state.cart.orphanedProductIds?.map((id) =>
          Relay.toGlobalId("Product", id)
        ) || [],
      ignoreActivePlan: Boolean(validateForm.state.cart.forcePlanSelection),
    }
  )

  const plans = Relay.connectionToArray(organization?.plansInclusiveOfProducts)
  const plansById = ArrayUtils.mapBy(plans, "id")

  if (!plans.length) {
    return (
      <DiscoAlert
        severity={"error"}
        message={"Cart is not compatible with any membership plans. Please remove items."}
      />
    )
  }

  // If there is only a single plan, select it automatically
  if (plans.length === 1 && !validateForm.state.cart.forcePlanSelection) {
    handleSelectPlan(plans[0].id)
    return null
  }

  return (
    <DiscoSection
      testid={testid}
      groovyDepths={"insideCard"}
      className={classes.container}
      padding={0}
    >
      <div className={classes.header}>
        <DiscoText variant={"body-md-600"}>{"Select a Membership"}</DiscoText>
        <DiscoText variant={"body-sm"} color={"text.secondary"}>
          {"You must select a membership plan that includes everything in your cart."}
        </DiscoText>
      </div>

      <ScrollShadowContainer>
        <div className={classes.list}>
          {plans.map((plan) => {
            if (!plan.pricing) return null

            return (
              <span
                key={plan.id}
                onMouseEnter={() => (validateForm.state.hoveredPlanId = plan.id)}
                onMouseLeave={() => (validateForm.state.hoveredPlanId = undefined)}
                className={classes.listItem}
              >
                <CheckoutEntityListItem
                  testid={`${testid}.item.${plan.slug}`}
                  pricingKey={plan.pricing}
                  onSelect={() => handleSelectPlan(plan.id)}
                  buttons={[
                    <DiscoIconButton
                      key={`view-plan-${plan.id}`}
                      className={classes.button}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        navigateToPlan(plan.id)
                      }}
                      tooltip={"View Membership Plan"}
                    >
                      <DiscoIcon icon={"external-link"} />
                    </DiscoIconButton>,
                  ]}
                />
              </span>
            )
          })}
        </div>
      </ScrollShadowContainer>
    </DiscoSection>
  )

  function navigateToPlan(planId: GlobalID) {
    window.open(
      generatePath(ROUTE_NAMES.PRODUCT.REGISTRATION.ROOT, {
        productSlug: plansById[planId].slug,
      }),
      "_blank"
    )
  }

  // When selecting a plan, we need to add the plan to the cart
  // and any orphaned products that are associated with the plan.
  function handleSelectPlan(planId: GlobalID) {
    runInAction(async () => {
      const plan = plansById[planId]
      if (!plan?.pricing) return

      // Get the membership benefit in the cart
      const benefits = Relay.connectionToArray(plan.membershipBenefits)
      const benefitByProductId = ArrayUtils.mapBy(benefits, "productId")

      // Get each benefit of the orphaned product on the selected plans and add them to the cart
      for (const rawProductId of validateForm.state.cart.orphanedProductIds || []) {
        const productId = Relay.toGlobalId("Product", rawProductId)

        // Move the orphaned product to the cart
        validateForm.state.cart.orphanedProductIds?.remove(rawProductId)
        validateForm.state.cart.items.push({
          pricingId: Relay.rawId(benefitByProductId[productId].pricing.id),
        })
      }

      // Add the plan to the cart
      validateForm.state.cart.items.push({ pricingId: Relay.rawId(plan.pricing.id) })
      validateForm.state.selectedPlanPricingId = plan.pricing.id
      validateForm.state.cart.forcePlanSelection = false

      // Update the cart URL and validate the checkout
      const cart = CheckoutUtils.encodeCart(validateForm.state.cart)
      if (drawer.isOpen) {
        drawer.setParams({ cart })
      } else {
        history.replace(generatePath(ROUTE_NAMES.CHECKOUT.SUMMARY, { cart }))
      }

      await validateCheckout()
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    borderRadius: theme.measure.borderRadius.big,
    border: theme.palette.constants.borderSmall,
    boxShadow: theme.palette.groovyDepths.xs,
  },
  header: {
    backgroundColor: theme.palette.groovy.neutral[100],
    borderBottom: theme.palette.constants.borderSmall,
    padding: theme.spacing(1.5),
    borderTopLeftRadius: theme.measure.borderRadius.big,
    borderTopRightRadius: theme.measure.borderRadius.big,
  },
  list: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: theme.spacing(1.5),

    [theme.breakpoints.down("xs")]: {
      height: "500px",
    },
  },
  listItem: {
    paddingBottom: theme.spacing(1.5),
  },
  button: {
    backgroundColor: theme.palette.background.paper,
    width: "28px",
    height: "28px",
    padding: theme.spacing(0.5),
    border: theme.palette.constants.borderSmall,
  },
}))

export function CheckoutSummaryPlanSelectionSkeleton() {
  const classes = useStyles()

  return (
    <DiscoSection groovyDepths={"insideCard"} className={classes.container} padding={0}>
      <div className={classes.header}>
        <DiscoText variant={"body-md-600"}>{"Select a Membership"}</DiscoText>
        <DiscoText variant={"body-sm"} color={"text.secondary"}>
          {"You must select a membership plan that includes everything in your cart."}
        </DiscoText>
      </div>

      <div className={classes.list}>
        <CheckoutEntityListItemSkeleton />
      </div>
    </DiscoSection>
  )
}

export default Relay.withSkeleton({
  component: observer(CheckoutSummaryPlanSelection),
  skeleton: CheckoutSummaryPlanSelectionSkeleton,
})
