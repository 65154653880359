/**
 * @generated SignedSource<<620f50a710d1c889aa65ff9c60c17d7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentUtils_useTotalCommentsOnUsageCount$data = {
  readonly totalComments: number;
  readonly " $fragmentType": "CommentUtils_useTotalCommentsOnUsageCount";
};
export type CommentUtils_useTotalCommentsOnUsageCount$key = {
  readonly " $data"?: CommentUtils_useTotalCommentsOnUsageCount$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentUtils_useTotalCommentsOnUsageCount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentUtils_useTotalCommentsOnUsageCount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalComments",
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "cd8eaf527e730db845596a088d5b1e1a";

export default node;
