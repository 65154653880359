/**
 * @generated SignedSource<<d3181461e03da9a01a994b2ff84e83b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CheckoutPaymentQuery$variables = {
  id: string;
};
export type CheckoutPaymentQuery$data = {
  readonly validCheckout: {
    readonly stripeCheckoutClientSecret?: string | null;
  } | null;
};
export type CheckoutPaymentQuery = {
  response: CheckoutPaymentQuery$data;
  variables: CheckoutPaymentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stripeCheckoutClientSecret",
      "storageKey": null
    }
  ],
  "type": "ValidCheckout",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckoutPaymentQuery",
    "selections": [
      {
        "alias": "validCheckout",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CheckoutPaymentQuery",
    "selections": [
      {
        "alias": "validCheckout",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "675173e17ba58abf43c3a325be51aa79",
    "id": null,
    "metadata": {},
    "name": "CheckoutPaymentQuery",
    "operationKind": "query",
    "text": "query CheckoutPaymentQuery(\n  $id: ID!\n) {\n  validCheckout: node(id: $id) {\n    __typename\n    ... on ValidCheckout {\n      stripeCheckoutClientSecret\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "330cde3c45a68a0482d7be2fac72fbd3";

export default node;
