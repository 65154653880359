import { CheckoutProductListItemQuery } from "@/checkout/components/__generated__/CheckoutProductListItemQuery.graphql"
import CheckoutListItemTemplate, {
  CheckoutListItemTemplateSkeleton,
} from "@/checkout/components/CheckoutListItemTemplate"
import { useLabel } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { Pricing } from "@/pricing/pricingUtils"
import ProductUtils from "@/product/util/productUtils"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoChipColor, DiscoIcon, DiscoIconButton } from "@disco-ui"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useLazyLoadQuery } from "react-relay"
import { generatePath } from "react-router-dom"
import { graphql } from "relay-runtime"

export type Props = TestIDProps & {
  productId: GlobalID
  planId?: GlobalID | null
  onRemove?: (id: GlobalID) => void
}

function CheckoutProductListItem({
  testid = "CheckoutProductListItem",
  productId,
  planId,
  onRemove,
}: Props) {
  const experienceLabel = useLabel("experience")
  const pathwayLabel = useLabel("pathway")
  const classes = useStyles()

  const { product } = useLazyLoadQuery<CheckoutProductListItemQuery>(
    graphql`
      query CheckoutProductListItemQuery($id: ID!) {
        product: node(id: $id) {
          ... on Product {
            id
            name
            cover
            startsAt
            endsAt
            type
            slug
            includedInBenefits(hideDrafts: true) {
              edges {
                node {
                  id
                  membershipPlanId
                  pricing {
                    id
                    amountCents
                    frequency
                    kind
                  }
                }
              }
            }
          }
        }
      }
    `,
    { id: productId }
  )

  if (!product) return null

  const benefits = Relay.connectionToArray(product.includedInBenefits)
  const benefitByPlanId = ArrayUtils.mapBy(benefits, "membershipPlanId")
  const allSamePricing = benefits.every(
    (benefit) =>
      benefit.pricing.amountCents === benefits[0].pricing.amountCents &&
      benefit.pricing.frequency === benefits[0].pricing.frequency
  )

  return (
    <CheckoutListItemTemplate
      id={productId}
      testid={testid}
      name={renderName()}
      pricing={renderPricing()}
      details={renderEntityDetailsText()}
      chipLabel={renderLabel()}
      chipColor={renderChipColor()}
      cover={product.cover}
      onRemove={onRemove}
      buttons={[
        <DiscoIconButton
          key={`view-product-${productId}`}
          className={classes.button}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            navigateToEntity()
          }}
          tooltip={`View ${renderLabel()}`}
        >
          <DiscoIcon icon={"external-link"} />
        </DiscoIconButton>,
        <DiscoIconButton
          key={`remove-product-${productId}`}
          className={classes.button}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onRemove?.(productId)
          }}
          tooltip={"Remove"}
        >
          <DiscoIcon icon={"close"} />
        </DiscoIconButton>,
      ]}
    />
  )

  function renderName() {
    if (product?.name) return product.name
    if (product?.type === "course") return experienceLabel.singular
    if (product?.type === "pathway") return pathwayLabel.singular
    return "Product"
  }

  function renderLabel() {
    if (product?.type === "course") return experienceLabel.singular
    if (product?.type === "pathway") return pathwayLabel.singular
    return "Product"
  }

  function renderChipColor(): DiscoChipColor {
    if (product?.type === "pathway") return "purple"
    return "yellow"
  }

  function renderPricing(): Pricing | string {
    if (allSamePricing) return benefits[0].pricing
    if (planId) return benefitByPlanId[planId]?.pricing
    return "--"
  }

  function renderEntityDetailsText() {
    if (!product?.startsAt) return ""

    const startDate = new Date(product.startsAt)
    return `Starts ${formatDateWithOptions({
      format: DATE_FORMAT.DEFAULT,
    })(startDate)} · ${ProductUtils.displayDuration(product)}`
  }

  function navigateToEntity() {
    if (!product?.slug) return

    window.open(
      generatePath(ROUTE_NAMES.PRODUCT.REGISTRATION.ROOT, {
        productSlug: product.slug,
      }),
      "_blank"
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.background.paper,
    width: "28px",
    height: "28px",
    padding: theme.spacing(0.5),
    border: theme.palette.constants.borderSmall,
  },
}))

export function CheckoutProductListItemSkeleton() {
  return <CheckoutListItemTemplateSkeleton />
}

export default Relay.withSkeleton({
  component: observer(CheckoutProductListItem),
  skeleton: CheckoutProductListItemSkeleton,
})
