import ThreadLatestReplyPreview from "@/chat/sidebar/threads/ThreadLatestReplyPreview"
import { ThreadsSidebarListItemFragment$key } from "@/chat/sidebar/threads/__generated__/ThreadsSidebarListItemFragment.graphql"
import { useStreamChannel } from "@/core/context/StreamChatContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Relay from "@/relay/relayUtils"
import { DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { DiscoCardSkeleton } from "@disco-ui/card/DiscoCard"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { useEffect, useState } from "react"
import { useFragment } from "react-relay"
import { generatePath, useHistory, useLocation } from "react-router-dom"
import { graphql } from "relay-runtime"
import { MessageResponse } from "stream-chat"
import { DefaultStreamChatGenerics, useChatContext } from "stream-chat-react"

export type StreamMessageWithDiscoData = MessageResponse<DefaultStreamChatGenerics> & {
  user?:
    | (MessageResponse["user"] & {
        disco_user_id: string
        avatar?: string
      })
    | null
}

type Props = TestIDProps & {
  threadKey: ThreadsSidebarListItemFragment$key
}

export default function ThreadsSidebarListItem(props: Props) {
  const { testid = "ThreadsSidebarListItem", threadKey } = props
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles()
  const theme = useTheme()

  const thread = useFragment<ThreadsSidebarListItemFragment$key>(
    graphql`
      fragment ThreadsSidebarListItemFragment on Thread {
        id
        messageId
        chatChannel {
          externalChannelId
          product {
            name
          }
          app {
            customAppTitle
          }
          chatChannelMembers(first: 3, excludeViewer: true) {
            totalCount
            edges {
              node {
                id
                user {
                  id
                  fullName
                }
              }
            }
          }
        }
        viewerThreadMember {
          readAt
        }
      }
    `,
    threadKey
  )

  // Get the thread parent message and the latest reply using the Stream client
  const [parentMessage, setParentMessage] = useState<StreamMessageWithDiscoData | null>(
    null
  )
  const [latestReply, setLatestReply] = useState<StreamMessageWithDiscoData | null>(null)
  const { client: stream } = useChatContext()
  const channel = useStreamChannel(thread.chatChannel.externalChannelId)
  useEffect(() => {
    ;(async () => {
      if (!channel) return
      try {
        const { message } = await stream.getMessage(thread.messageId)
        setParentMessage(message as StreamMessageWithDiscoData)
        const { messages } = await channel.getReplies(
          thread.messageId,
          message.reply_count
            ? { limit: 1, offset: message.reply_count - 1 }
            : { limit: 99 }
        )
        const reply = messages[messages.length - 1]
        if (reply) setLatestReply(reply as StreamMessageWithDiscoData)
      } catch (err) {
        console.error(err)
      }
    })()
  }, [stream, thread.messageId, channel])

  if (!channel) return null
  if (!parentMessage || !latestReply) return <ThreadsSidebarListItemSkeleton />

  const channelName = getChannelName()

  return (
    <DiscoContainerButton
      className={classNames(classes.container, {
        [classes.selected]: location.pathname.endsWith(thread.id),
      })}
      onClick={() => {
        history.push(
          generatePath(ROUTE_NAMES.COMMUNITY.THREADS.DETAIL, { threadId: thread.id })
        )
      }}
      testid={testid}
    >
      <DiscoText
        variant={"body-xs"}
        color={
          theme.palette.type === "dark" ? "groovy.neutral.600" : "groovy.neutral.400"
        }
        display={"block"}
        marginBottom={1}
      >
        {"Thread in "}
        <DiscoText
          component={"span"}
          variant={"body-xs-500"}
          color={"primary.main"}
          title={channelName}
        >
          {channelName}
        </DiscoText>
        {thread.chatChannel.product && (
          <>
            {" from "}
            <DiscoText component={"span"} variant={"body-xs-500"} color={"primary.main"}>
              {thread.chatChannel.product.name}
            </DiscoText>
          </>
        )}
      </DiscoText>
      <ThreadLatestReplyPreview
        message={latestReply}
        parentMessage={parentMessage}
        isUnread={thread.viewerThreadMember?.readAt === null}
      />
    </DiscoContainerButton>
  )

  function getChannelName() {
    if (thread.chatChannel.app) {
      return `#${thread.chatChannel.app.customAppTitle}`
    }
    const members = Relay.connectionToArray(thread.chatChannel.chatChannelMembers)
    let name = members.map((ccm) => ccm.user.fullName).join(", ")
    const { totalCount } = thread.chatChannel.chatChannelMembers
    if (totalCount > members.length) {
      name += ` + ${totalCount - members.length} more`
    }
    return name
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    padding: theme.spacing(1, 1.5),
    margin: theme.spacing(0, 1.5),
    borderRadius: theme.measure.borderRadius.big,
    "&:hover": {
      backgroundColor: theme.palette.groovy.neutral[100],
    },
  },
  selected: {
    "&, &:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "& span, & p": {
      color: theme.palette.primary.contrastText,
    },
  },
  skeleton: {
    margin: theme.spacing(0, 1.5),
    width: "auto !important",
  },
}))

export function ThreadsSidebarListItemSkeleton() {
  const classes = useStyles()
  return <DiscoCardSkeleton variant={"compact"} classes={{ card: classes.skeleton }} />
}
