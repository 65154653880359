import SidebarContactAdminsCarouselItem, {
  SidebarContactAdminsCarouselItemSkeleton,
} from "@/chat/sidebar/contact-admins/SidebarContactAdminsCarouselItem"
import { SidebarContactAdminsCarouselPaginationQuery } from "@/chat/sidebar/contact-admins/__generated__/SidebarContactAdminsCarouselPaginationQuery.graphql"
import { SidebarContactAdminsCarouselQuery } from "@/chat/sidebar/contact-admins/__generated__/SidebarContactAdminsCarouselQuery.graphql"
import { SidebarContactAdminsCarousel_PaginationFragment$key } from "@/chat/sidebar/contact-admins/__generated__/SidebarContactAdminsCarousel_PaginationFragment.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import DiscoExpand from "@/expand/DiscoExpand"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoCarousel, DiscoCarouselSkeleton, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { graphql, useLazyLoadQuery, usePaginationFragment } from "react-relay"

const ADMINS_PER_LOAD = 3

function SidebarContactAdminsCarousel() {
  const activeOrganization = useActiveOrganization()!
  const classes = useStyles()
  const theme = useTheme()

  const { node } = useLazyLoadQuery<SidebarContactAdminsCarouselQuery>(
    graphql`
      query SidebarContactAdminsCarouselQuery(
        $id: ID!
        $first: Int
        $after: String
        $last: Int
        $before: String
      ) {
        node(id: $id) {
          ... on Organization {
            ...SidebarContactAdminsCarousel_PaginationFragment
              @arguments(first: $first, after: $after, last: $last, before: $before)
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
      first: ADMINS_PER_LOAD,
    },
    { fetchPolicy: "network-only", fetchKey: "sidebar-conact-admins-carousel" }
  )

  const { data, hasNext, hasPrevious, loadNext, isLoadingNext } = usePaginationFragment<
    SidebarContactAdminsCarouselPaginationQuery,
    SidebarContactAdminsCarousel_PaginationFragment$key
  >(
    graphql`
      fragment SidebarContactAdminsCarousel_PaginationFragment on Organization
      @refetchable(queryName: "SidebarContactAdminsCarouselPaginationQuery")
      @argumentDefinitions(
        first: { type: "Int" }
        after: { type: "String" }
        last: { type: "Int" }
        before: { type: "String" }
      ) {
        organizationMemberships(
          first: $first
          after: $after
          last: $last
          before: $before
          roles: [owner, admin]
          excludeViewer: true
          orderBy: member_name
          direction: ASC
        ) @connection(key: "SidebarContactAdminsCarousel_organizationMemberships") {
          __id
          totalCount
          edges {
            cursor
            node {
              id
              ...SidebarContactAdminsCarouselItemFragment
            }
          }
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    node
  )

  const oms = Relay.connectionToArray(data?.organizationMemberships)
  if (!data?.organizationMemberships?.totalCount) return null

  return (
    <DiscoExpand
      testid={"SidebarContactAdminsCarousel"}
      header={
        <DiscoText
          variant={"body-sm-500-uppercase"}
          color={
            theme.palette.type === "dark" ? "groovy.neutral.600" : "groovy.neutral.400"
          }
        >
          {"Contact Admins"}
        </DiscoText>
      }
      variant={"caret"}
      isDefaultExpanded={false}
      mountOnExpand={false}
    >
      <DiscoCarousel
        className={classes.carousel}
        data={oms}
        item={(om, i) => (
          <SidebarContactAdminsCarouselItem
            key={om.id}
            organizationMembershipKey={om}
            testid={`SidebarContactAdminsCarouselItem.${i}`}
          />
        )}
        slidesPerView={1.75}
        totalCount={data?.organizationMemberships?.totalCount}
        refetch={{
          hasNext,
          hasPrevious,
          loadMore: () => loadNext(ADMINS_PER_LOAD),
          isLoading: isLoadingNext,
        }}
        itemSkeleton={<SidebarContactAdminsCarouselItemSkeleton />}
      />
    </DiscoExpand>
  )
}

const useStyles = makeUseStyles((theme) => ({
  carousel: {
    margin: theme.spacing(-1, 0, -1.5),
    padding: 0,
    backgroundColor: theme.palette.background.paper,
  },
}))

function SidebarContactAdminsCarouselSkeleton() {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <DiscoExpand
      header={
        <DiscoText
          variant={"body-sm-500-uppercase"}
          color={
            theme.palette.type === "dark" ? "groovy.neutral.600" : "groovy.neutral.400"
          }
        >
          {"Contact Admins"}
        </DiscoText>
      }
      variant={"caret"}
      isDefaultExpanded={false}
    >
      <DiscoCarouselSkeleton
        className={classes.carousel}
        item={<SidebarContactAdminsCarouselItemSkeleton />}
        slidesPerView={1.75}
        showHeader={false}
      />
    </DiscoExpand>
  )
}

export default Relay.withSkeleton({
  component: SidebarContactAdminsCarousel,
  skeleton: SidebarContactAdminsCarouselSkeleton,
})
