/**
 * @generated SignedSource<<e143b695ecc85560ca5ab2690af74bf6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ProcessCheckoutInput = {
  validCheckoutId: string;
};
export type CheckoutProcessMutation$variables = {
  input: ProcessCheckoutInput;
};
export type CheckoutProcessMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly success: boolean | null;
  };
};
export type CheckoutProcessMutation = {
  response: CheckoutProcessMutation$data;
  variables: CheckoutProcessMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ProcessCheckoutResponse",
    "kind": "LinkedField",
    "name": "processCheckout",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckoutProcessMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CheckoutProcessMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e80e9e955379a5539db7b343139df5d5",
    "id": null,
    "metadata": {},
    "name": "CheckoutProcessMutation",
    "operationKind": "mutation",
    "text": "mutation CheckoutProcessMutation(\n  $input: ProcessCheckoutInput!\n) {\n  response: processCheckout(input: $input) {\n    success\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a18de65d77776176376fd673d7d1d910";

export default node;
