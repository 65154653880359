import {
  useBotSuggestionContext,
  useBotThreadSuggestion,
} from "@/chat/channel/BotSuggestionsContext"
import { ChatChannelThreadContentFragment$key } from "@/chat/channel/page/content/__generated__/ChatChannelThreadContentFragment.graphql"
import { ChatChannelThreadContent_AcceptBotSuggestionMutation } from "@/chat/channel/page/content/__generated__/ChatChannelThreadContent_AcceptBotSuggestionMutation.graphql"
import { ChatChannelThreadContent_DismissBotSuggestionMutation } from "@/chat/channel/page/content/__generated__/ChatChannelThreadContent_DismissBotSuggestionMutation.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { sendSentryAnException } from "@/core/sentryHandler"
import ChatChannelSimpleMessage from "@components/chat/channel/detail/message/ChatChannelMessage"
import ChatChannelMessageInput from "@components/chat/channel/message-input/ChatChannelMessageInput"
import {
  MESSAGE_ACTIONS,
  groupStyles,
  processDiscoUrls,
} from "@components/chat/util/chatUtils"
import { useCallback } from "react"
import { useFragment, useMutation } from "react-relay"
import { graphql } from "relay-runtime"
import { Message, UserResponse } from "stream-chat"
import {
  DefaultStreamChatGenerics,
  MessageToSend,
  Thread,
  useChannelActionContext,
  useChannelStateContext,
} from "stream-chat-react"

interface Props {
  chatChannelKey: ChatChannelThreadContentFragment$key
}

function ChatChannelThreadContent({ chatChannelKey }: Props) {
  const { sendMessage } = useChannelActionContext()
  const { members, thread } = useChannelStateContext()
  const activeOrganization = useActiveOrganization()!

  const chatChannel = useFragment<ChatChannelThreadContentFragment$key>(
    graphql`
      fragment ChatChannelThreadContentFragment on ChatChannel {
        id
        ...ChatChannelMessageFragment
      }
    `,
    chatChannelKey
  )

  const ThreadInput = useCallback(
    () => <ChatChannelMessageInput chatChannelId={chatChannel.id} forThread />,
    [chatChannel.id]
  )

  const [acceptSuggestion] =
    useMutation<ChatChannelThreadContent_AcceptBotSuggestionMutation>(graphql`
      mutation ChatChannelThreadContent_AcceptBotSuggestionMutation($id: ID!) {
        acceptBotSuggestion(id: $id) {
          node {
            id @deleteRecord
          }
        }
      }
    `)

  const [dismissSuggestion] =
    useMutation<ChatChannelThreadContent_DismissBotSuggestionMutation>(graphql`
      mutation ChatChannelThreadContent_DismissBotSuggestionMutation($id: ID!) {
        dismissBotSuggestion(id: $id) {
          node {
            id @deleteRecord
          }
        }
      }
    `)

  const { botSuggestion } = useBotThreadSuggestion({ threadId: thread?.id || "" })
  const { selectedSuggestion, setSelectedSuggestion } = useBotSuggestionContext()

  const overrideSubmitHandler = useCallback(
    async (
      message: MessageToSend,
      _channelCid: string,
      customMessageData?: Partial<Message<DefaultStreamChatGenerics>>
    ) => {
      // Add any additional users mentioned in the selected bot suggested message
      const additionalBotMentionedUsers = getAdditionalBotMentionedUsers(message)

      message.mentioned_users = [
        ...(message.mentioned_users || []),
        ...additionalBotMentionedUsers,
      ]

      if (typeof message.text !== "string") {
        await sendMessage(message, customMessageData)
        return
      }
      try {
        const processedText = await processDiscoUrls(message.text, activeOrganization)
        const newMessage = {
          ...message,
          text: processedText,
        }
        await sendMessage(newMessage, customMessageData)
      } catch (error) {
        await sendMessage(message, customMessageData)
        sendSentryAnException(error, {
          extra: {
            title: "processDiscoUrlsFailed",
          },
        })
      }
      if (selectedSuggestion) {
        acceptSuggestion({ variables: { id: selectedSuggestion.suggestion.id } })
        setSelectedSuggestion(null)
        // If an admin manually typed and sent a message, dismiss the bot suggestion
        // Only admins can query bot suggestions, so we can assume that if there is a bot suggestion, the user is an admin
      } else if (botSuggestion) {
        dismissSuggestion({ variables: { id: botSuggestion.id } })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sendMessage, selectedSuggestion]
  )

  return (
    <Thread
      Input={ThreadInput}
      additionalVirtualizedMessageListProps={{
        messageActions: MESSAGE_ACTIONS,
        shouldGroupByUser: true,
        groupStyles,
        defaultItemHeight: 64,
        stickToBottomScrollBehavior: "auto",
        additionalVirtuosoProps: {
          followOutput: () => true,
        },
      }}
      additionalMessageInputProps={{
        overrideSubmitHandler,
      }}
      virtualized
      Message={(props) => (
        <ChatChannelSimpleMessage firstOfGroup {...props} chatChannelKey={chatChannel} />
      )}
    />
  )

  /**
   * Since we can not add users to the mentioned_users array when inserting the selected suggestion into
   * the thread input, we must add these users to the mentioned_user list when the message is sent
   *
   */
  function getAdditionalBotMentionedUsers(message: MessageToSend) {
    if (!selectedSuggestion?.suggestion.suggestedMentionedPlatformUserIds) return []

    const mentionedStreamUserIds = message.mentioned_users?.map((user) => user.id) || []

    // Add any additional users mentioned in the selected bot suggested message
    return (
      selectedSuggestion.suggestion.suggestedMentionedPlatformUserIds
        // Ignore any members that were already manually selected while editing the message
        .filter((memberStreamId) => !mentionedStreamUserIds.includes(memberStreamId))
        .map((memberStreamId) => members?.[memberStreamId].user)
        .filter((streamMember) => {
          if (!streamMember || !streamMember.name) return false
          // Ignore any members that were removed from the message when editings
          return message.text?.includes(streamMember.name)
        }) as UserResponse<DefaultStreamChatGenerics>[]
    )
  }
}

export default ChatChannelThreadContent
