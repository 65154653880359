/**
 * @generated SignedSource<<8510c455048eabf72e6516fba5745629>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BotSuggestionsContextFragment$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly id: string;
      readonly messageChannel: string | null;
      readonly messageThreadTs: string | null;
      readonly messageTs: string | null;
      readonly suggestedMentionedPlatformUserIds: ReadonlyArray<string> | null;
      readonly suggestedMessage: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"ChatChannelBotSuggestedMessageFragment">;
    };
  }>;
  readonly " $fragmentType": "BotSuggestionsContextFragment";
};
export type BotSuggestionsContextFragment$key = {
  readonly " $data"?: BotSuggestionsContextFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BotSuggestionsContextFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BotSuggestionsContextFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BotQueuedMessageNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BotQueuedMessage",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "messageChannel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "messageThreadTs",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "messageTs",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "suggestedMessage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "suggestedMentionedPlatformUserIds",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ChatChannelBotSuggestedMessageFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BotQueuedMessageNodeConnection",
  "abstractKey": null
};

(node as any).hash = "de22a5cbd214014ba92ca9fc1ffdc3fd";

export default node;
