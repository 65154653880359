/**
 * @generated SignedSource<<0d3a59a05e2a31e658bc04103fdcbd4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SidebarContactAdminsCarouselItemFragment$data = {
  readonly id: string;
  readonly member: {
    readonly fullName: string;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarFragment">;
  };
  readonly " $fragmentType": "SidebarContactAdminsCarouselItemFragment";
};
export type SidebarContactAdminsCarouselItemFragment$key = {
  readonly " $data"?: SidebarContactAdminsCarouselItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SidebarContactAdminsCarouselItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SidebarContactAdminsCarouselItemFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "member",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProfileAvatarFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrganizationMembership",
  "abstractKey": null
};
})();

(node as any).hash = "380fca6b0f8237c8fb0279eca9bb9507";

export default node;
