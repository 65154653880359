/**
 * @generated SignedSource<<bc585f8f01523ec9e7f99b0aee561ec6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentMoreActionsDropdownFragment$data = {
  readonly contentId: string;
  readonly id: string;
  readonly parentCommentId: string | null;
  readonly productId: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  readonly " $fragmentType": "CommentMoreActionsDropdownFragment";
};
export type CommentMoreActionsDropdownFragment$key = {
  readonly " $data"?: CommentMoreActionsDropdownFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentMoreActionsDropdownFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentMoreActionsDropdownFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentCommentId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    }
  ],
  "type": "Comment",
  "abstractKey": null
};

(node as any).hash = "f09bd88e6964b82f9f4f34918d0645ba";

export default node;
