/**
 * @generated SignedSource<<ab7165609d0525b3fa0e5ff39607aa72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentMoreActionBookmarkButtonQuery$variables = {
  id: string;
};
export type CommentMoreActionBookmarkButtonQuery$data = {
  readonly content: {
    readonly hasViewerBookmarked?: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"BookmarkButtonContentFragment">;
  } | null;
};
export type CommentMoreActionBookmarkButtonQuery = {
  response: CommentMoreActionBookmarkButtonQuery$data;
  variables: CommentMoreActionBookmarkButtonQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasViewerBookmarked",
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CommentMoreActionBookmarkButtonQuery",
    "selections": [
      {
        "alias": "content",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BookmarkButtonContentFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CommentMoreActionBookmarkButtonQuery",
    "selections": [
      {
        "alias": "content",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f10878cb544d67691ade7c57492252ce",
    "id": null,
    "metadata": {},
    "name": "CommentMoreActionBookmarkButtonQuery",
    "operationKind": "query",
    "text": "query CommentMoreActionBookmarkButtonQuery(\n  $id: ID!\n) {\n  content: node(id: $id) {\n    __typename\n    ... on Content {\n      hasViewerBookmarked\n    }\n    ...BookmarkButtonContentFragment\n    id\n  }\n}\n\nfragment BookmarkButtonContentFragment on Content {\n  id\n  hasViewerBookmarked\n}\n"
  }
};
})();

(node as any).hash = "bf800810f2531125f2025c7101cc75ae";

export default node;
