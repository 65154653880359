import ChannelsNavSection from "@/chat/sidebar/channels/ChannelsNavSection"
import ChannelsSidebarProduct from "@/chat/sidebar/channels/ChannelsSidebarProduct"
import { ChannelsSidebarQuery } from "@/chat/sidebar/channels/__generated__/ChannelsSidebarQuery.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import ChatChannelSideBarItem from "@/product/sidebar/chat/channels/ChatChannelSideBarItem"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoDivider,
  DiscoSideBarItemSkeleton,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import { range } from "@utils/array/arrayUtils"
import { Fragment, useEffect, useMemo } from "react"
import { useLazyLoadQuery } from "react-relay"
import { generatePath, Redirect, useHistory, useLocation } from "react-router-dom"
import { graphql } from "relay-runtime"

function ChannelsSidebar() {
  const activeOrganization = useActiveOrganization()!
  const classes = useStyles()

  const { organization } = useLazyLoadQuery<ChannelsSidebarQuery>(
    graphql`
      query ChannelsSidebarQuery($id: ID!) {
        organization: node(id: $id) {
          ... on Organization {
            apps(kinds: [chat_channel]) {
              edges {
                node {
                  id
                  ...ChatChannelSideBarItemFragment
                  chatChannel {
                    id
                  }
                }
              }
            }
            products(type: "course", noNavSection: true, viewerIsMember: true) {
              edges {
                node {
                  id
                  ...ChannelsSidebarProductFragment
                  productApps(kinds: [chat_channel, nav_folder]) {
                    edges {
                      node {
                        id
                        chatChannel {
                          id
                        }
                        productApps(kinds: [chat_channel]) {
                          edges {
                            node {
                              id
                              chatChannel {
                                id
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            navSections {
              edges {
                node {
                  id
                  kind
                  ...ChannelsNavSectionFragment
                  apps(kinds: [chat_channel]) {
                    edges {
                      node {
                        id
                        chatChannel {
                          id
                        }
                      }
                    }
                  }
                  products(viewerIsMember: true) {
                    edges {
                      node {
                        id
                        productApps(kinds: [chat_channel, nav_folder]) {
                          edges {
                            node {
                              id
                              chatChannel {
                                id
                              }
                              productApps(kinds: [chat_channel]) {
                                edges {
                                  node {
                                    id
                                    chatChannel {
                                      id
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    { id: activeOrganization.id },
    { fetchPolicy: "network-only", fetchKey: "channels-side-bar" }
  )

  const apps = Relay.connectionToArray(organization?.apps)
  const products = Relay.connectionToArray(organization?.products).filter((p) =>
    p.productApps.edges.some(
      (a) => a.node.chatChannel || a.node.productApps?.edges.length
    )
  )
  const navSections = Relay.connectionToArray(organization?.navSections)
  const communitySections = navSections.filter(
    (ns) => ns.kind === "custom" && ns.apps.edges.length
  )
  const productsSections = navSections.filter(
    (ns) =>
      ns.kind === "products" &&
      ns.products.edges.some((p) =>
        p.node.productApps.edges.some(
          (a) => a.node.chatChannel || a.node.productApps?.edges.length
        )
      )
  )

  const allApps = useMemo(() => {
    return (apps as { chatChannel: { id: string } | null }[])
      .concat(communitySections.flatMap((s) => Relay.connectionToArray(s.apps)))
      .concat(
        products.flatMap((p) =>
          Relay.connectionToArray(p.productApps).flatMap((a) =>
            a.chatChannel ? a : Relay.connectionToArray(a.productApps)
          )
        )
      )
      .concat(
        productsSections.flatMap((s) =>
          Relay.connectionToArray(s.products).flatMap((p) =>
            Relay.connectionToArray(p.productApps).flatMap((a) =>
              a.chatChannel ? a : Relay.connectionToArray(a.productApps)
            )
          )
        )
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apps.length, products.length, navSections.length])

  // Redirect to the first channel in the list of none is currenly selected
  const location = useLocation()
  const history = useHistory()
  useEffect(() => {
    if (location.pathname !== ROUTE_NAMES.CHAT.CHANNEL.ROOT) return
    if (!allApps.length) {
      history.replace(ROUTE_NAMES.CHAT.CHANNEL.DETAIL_EMPTY)
      return
    }
    history.replace(
      generatePath(ROUTE_NAMES.CHAT.CHANNEL.DETAIL, {
        channelId: allApps[0].chatChannel!.id,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, history])

  if (!activeOrganization.isChannelsEnabled) {
    return <Redirect to={ROUTE_NAMES.COMMUNITY.HOME.ROOT} />
  }

  if (!allApps.length) {
    return (
      <div className={classes.inner}>
        <DiscoText
          variant={"body-sm"}
          color={"groovy.neutral.400"}
          marginTop={2}
          align={"center"}
        >
          {"No channels yet."}
        </DiscoText>
      </div>
    )
  }

  return (
    <div className={classes.outer}>
      <div className={classes.inner}>
        {apps.map((app) => (
          <ChatChannelSideBarItem
            key={app.id}
            testid={"ChannelsSidebar.community-top-level-channels"}
            appKey={app}
            isChannelsSidebar
          />
        ))}
      </div>
      {communitySections.map((section, i) => (
        <Fragment key={section.id}>
          <DiscoDivider marginTop={1.5} marginBottom={1.5} />
          <div className={classes.inner}>
            <ChannelsNavSection
              testid={`CommunityNavSection.${i}`}
              navSectionKey={section}
            />
          </div>
        </Fragment>
      ))}
      {products.length > 0 && (
        <>
          <DiscoDivider marginTop={1.5} marginBottom={1.5} />
          <div className={classes.inner}>
            {products.map((product, i) => (
              <ChannelsSidebarProduct
                key={product.id}
                testid={`TopLevelProducts.${i}`}
                productKey={product}
              />
            ))}
          </div>
        </>
      )}
      {productsSections.map((section, i) => (
        <Fragment key={section.id}>
          <DiscoDivider marginTop={1.5} marginBottom={1.5} />
          <div className={classes.inner}>
            <ChannelsNavSection
              key={section.id}
              testid={`ProductsNavSection.${i}`}
              navSectionKey={section}
            />
          </div>
        </Fragment>
      ))}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  outer: {
    padding: theme.spacing(0, 0, 2),
  },
  inner: {
    padding: theme.spacing(0, 2),
  },
}))

function ChannelsSidebarSkeleton() {
  const classes = useStyles()
  return (
    <div
      className={classes.inner}
      style={{ display: "flex", flexDirection: "column", gap: 1 }}
    >
      {range(4).map((i) => (
        <DiscoSideBarItemSkeleton key={i} />
      ))}
      {range(2).map((i) => (
        <Fragment key={i}>
          <DiscoDivider />
          <DiscoTextSkeleton width={"100px"} />
          <DiscoSideBarItemSkeleton />
          <DiscoSideBarItemSkeleton />
        </Fragment>
      ))}
    </div>
  )
}

export default Relay.withSkeleton({
  component: ChannelsSidebar,
  skeleton: ChannelsSidebarSkeleton,
})
