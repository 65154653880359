import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawer } from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"
import { TestIDProps } from "@utils/typeUtils"

const CheckoutDrawerContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "checkout-drawer-content" */ "@/checkout/drawer/CheckoutDrawerContent"
    )
)

export type Props = TestIDProps

export default function CheckoutDrawer({ testid = "CheckoutDrawer" }: Props) {
  const drawer = useGlobalDrawer("checkout")
  const classes = useStyles()
  const { cart, validCheckoutId, checkoutStep } = drawer.params
  const shouldRenderContent = Boolean(cart || validCheckoutId || checkoutStep)

  return (
    <DiscoDrawer
      testid={testid}
      open={drawer.isOpen}
      size={"xl"}
      containerClasses={{ drawerContainer: classes.drawerContainer }}
    >
      {shouldRenderContent && <CheckoutDrawerContent />}
    </DiscoDrawer>
  )
}

const useStyles = makeUseStyles({
  drawerContainer: {
    padding: 0,
  },
})
